/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {ListTable} from '../../../modules/comman/ListComponenets/ListTable'
import {connect} from 'react-redux'
import * as order from '../redux/OrderRedux'
import {Tooltip, Popconfirm, Input} from 'antd'
import {Switch} from 'antd'
import {Button} from 'antd'
import {UserOutlined, EditOutlined, DeleteOutlined, EyeOutlined} from '@ant-design/icons'
import {
  changeStatusOrderData,
  deleteOrderData,
  editOrderData,
  getOrderData,
  getSearchOrderData,
} from '../redux/OrderCRUD'
import {config} from '../../../comman/constants'
import {InputSearchText} from '../../../modules/comman/formComponents/InputSearchText'
import {History, LocationState} from 'history'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {withRouter} from 'react-router-dom'
import {RouteComponentProps} from 'react-router'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {CommanModal} from '../../../modules/comman/components/Modal'
import {EditOrderForm} from './EditOrderForm'
import {PreviewOrderForm} from './PreviewOrderForm'
import {IOrderFormValues} from './CreateOrderForm'
import moment from 'moment'
import {Tag} from 'antd'
import {returnStockId} from '../../../comman/helper'

const {Search} = Input
const PRIMARY_COLOR = config.buttonPrimaryColor
const TEXT_PRIMARY_COLOR = config.buttonTextPrimaryColor
interface IRecordsTableProps {
  orderData: any
  orderDataTotal: number
  orderLoading: boolean
  setListOrderData: (orderData: any) => void
  setListOrderDataTotal: (orderDataTotal: number) => void
  setOrderLoading: (orderLoading: boolean) => void
  history?: History<LocationState>
  loginUser?: any
}

interface IRecordsTableState {
  searchText: string
  isLoading: boolean
  startIndex: number
  limitNumber: number
  pagination: {
    pageSize: number
    current: number
  }
  orderSelectedData: any
}

type PathParamsType = {
  param1: string
}
class ListOrderData extends React.Component<
  RouteComponentProps<PathParamsType> & IRecordsTableProps,
  IRecordsTableState
> {
  constructor(props: any) {
    super(props)

    this.state = {
      isLoading: false,
      startIndex: 0,
      limitNumber: 10,
      pagination: {
        pageSize: 10,
        current: 1,
      },
      searchText: '',
      orderSelectedData: null,
    }
  }

  public handleTableChange = (pagination: any, filters: any) => {
    this.setState(
      {
        pagination,
      },
      () => this.getListOrderData(pagination)
    )
  }

  public getListOrderData = async (pagination: any) => {
    const {searchText} = this.state

    console.log('tempCounter && counter --> ', this.tempCounter, this.counter)
    const start = pagination.current * pagination.pageSize - pagination.pageSize
    const end = pagination.pageSize

    this.setState(
      {
        isLoading: true,
        startIndex: start,
        limitNumber: end,
      },
      async () => {
        if (searchText && searchText !== '') {
          this.listOrderSearchData(start, end)
        } else {
          this.listOrderData(start, end)
        }
      }
    )
  }

  public listOrderData = async (start: number, end: any) => {
    const {setOrderLoading} = this.props
    try {
      const {setListOrderData, setListOrderDataTotal} = this.props
      await setOrderLoading(true)
      let orderData = await getOrderData(start, end)
      if (orderData.data) {
        await setListOrderData(orderData.data.docs)
        await setListOrderDataTotal(orderData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setOrderLoading(false)
    }
  }

  public listOrderSearchData = async (start: number, end: any) => {
    const {setOrderLoading} = this.props
    try {
      const {setListOrderData, setListOrderDataTotal} = this.props
      const {searchText} = this.state

      await setOrderLoading(true)
      let orderData = await getSearchOrderData(start, end, searchText)
      if (orderData.data) {
        await setListOrderData(orderData.data.docs)
        await setListOrderDataTotal(orderData.data.total)
      }
    } catch (e) {
      console.log('!!!!Error', e)
    } finally {
      await setOrderLoading(false)
    }
  }

  public onSearchChange = async (value: string) => {
    try {
      this.setState(
        {
          searchText: value,
        },
        () => {
          this.listOrderSearchData(0, 10)
        }
      )
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public onCreateOrder = () => {
    const {history} = this.props
    history.push('/order/create')
  }

  public showPreviewModal = async (item: any) => {
    this.setState({
      orderSelectedData: item,
    })
  }

  public closeEditModal = async () => {
    this.setState({
      orderSelectedData: null,
    })
    this.listOrderData(this.state.startIndex, this.state.limitNumber)
  }

  counter = 0
  tempCounter = 0
  public adminColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order_code ? returnStockId(item.order_code) : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },
    {
      title: 'Full Name',
      key: 'Full Name',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>
              {item?.client_id?.first_name && item?.client_id?.last_name
                ? `${item.client_id.first_name} ${item.client_id.last_name}`
                : item?.client_id?.first_name
                ? item?.client_id?.first_name
                : '-'}
            </strong>
          </div>
        )
      },
    },
    {
      title: 'Email',
      key: 'email',
      render: (text: any, item: any, index: number) => {
        return <div>{item?.client_id?.email ? item.client_id.email : '-'}</div>
      },
    },
    {
      title: 'Order Date',
      key: 'order_date',
      render: (text: any, item: any, index: number) => {
        return <div>{item.order_date ? moment(item.order_date).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Quantity',
      key: 'stock_diamonds',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.buy_request_id && item.buy_request_id.stock_diamonds
              ? item.buy_request_id.stock_diamonds.length
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showPreviewModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public clientColumns = [
    {
      title: 'Sr. No.',
      key: 'serial_number',
      fixed: window.innerWidth < 768 ? false : true,
      width: 80,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <span>{++index + this.tempCounter}</span>
          </div>
        )
      },
    },
    {
      title: 'Order Code',
      key: 'order_code',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            <strong>{item.order_code ? returnStockId(item.order_code) : '-'}</strong>
          </div>
        )
      },
    },
    {
      title: 'Status',
      key: 'status',
      fixed: window.innerWidth < 768 ? false : true,
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.status ? (
              <Tag
                color={
                  config.statusTagColor[item.status]
                    ? config.statusTagColor[item.status]
                    : 'default'
                }
              >
                {item.status}
              </Tag>
            ) : (
              '-'
            )}
          </div>
        )
      },
    },

    {
      title: 'Order Date',
      key: 'order_date',
      render: (text: any, item: any, index: number) => {
        return <div>{item.order_date ? moment(item.order_date).format('DD-MM-YYYY') : '-'}</div>
      },
    },
    {
      title: 'Quantity',
      key: 'stock_diamonds',
      render: (text: any, item: any, index: number) => {
        return (
          <div>
            {item.buy_request_id && item.buy_request_id.stock_diamonds
              ? item.buy_request_id.stock_diamonds.length
              : '0'}
          </div>
        )
      },
    },
    {
      title: 'Actions',
      width: 130,
      key: 'action',
      render: (text: any, item: any, index: number) => {
        return (
          <div style={{display: 'inline-block', width: '100%'}}>
            <div style={{float: 'left', marginRight: '5px'}}>
              <Tooltip placement='bottom' title='Preview'>
                <Button
                  shape='circle'
                  icon={<EyeOutlined />}
                  onClick={() => {
                    this.showPreviewModal(item)
                  }}
                />
              </Tooltip>
            </div>
          </div>
        )
      },
    },
  ]

  public render() {
    const {orderData, orderDataTotal, orderLoading, loginUser} = this.props
    const {searchText, orderSelectedData} = this.state

    return (
      <div>
        <div>
          <div className='card card-custom'>
            <div className='card-body'>
              <div style={{overflowX: 'auto'}}>
                <ListTable
                  rowKey={(record: {_id: any}) => record._id}
                  onChange={this.handleTableChange}
                  onPaginationChange={(e: any) => {
                    if (e - 1) {
                      this.counter = this.tempCounter = (e - 1) * this.state.pagination.pageSize
                      return
                    }

                    this.counter = 0
                    this.tempCounter = 0
                  }}
                  column={loginUser?.role === 'admin' ? this.adminColumns : this.clientColumns}
                  paginationConfig={{
                    total: orderDataTotal,
                    showSizeChanger: true,
                    pageSize: this.state.pagination.pageSize,
                    current: this.state.pagination.current,
                  }}
                  isLoading={orderLoading}
                  dataSource={orderData}
                  counter={this.counter}
                  tempCounter={this.tempCounter}
                  isScroll={true}
                  scrollWidth={1200}
                />
                <div>
                  <CommanModal
                    show={orderSelectedData ? true : false}
                    handleClose={() => {
                      this.closeEditModal()
                    }}
                    title={'Preview Order'}
                    submitText={'Save Changes'}
                  >
                    <div>
                      {orderSelectedData && (
                        <PreviewOrderForm
                          data={orderSelectedData}
                          loginUserData={loginUser}
                          handleCancel={() => {
                            this.closeEditModal()
                          }}
                        />
                      )}
                    </div>
                  </CommanModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: any) => ({
  loginUser: state.auth.user,
  orderData: state.order.orderData,
  orderDataTotal: state.order.orderDataTotal,
  orderLoading: state.order.orderLoading,
})

const mapDispatchToProps = (dispatch: any) => {
  return {
    setListOrderData: (orderData: any) => {
      dispatch(order.actions.setOrderData(orderData))
    },
    setListOrderDataTotal: (orderDataTotal: number) => {
      dispatch(order.actions.setOrderDataTotal(orderDataTotal))
    },
    setOrderLoading: (orderLoading: boolean) => {
      dispatch(order.actions.setOrderLoading(orderLoading))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ListOrderData))
