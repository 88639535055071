import React from 'react'
import axios from 'axios'
import {BuyRequestModal} from '../models/BuyRequestModal'
import {IBuyRequestFormValues} from '../containers/CreateBuyRequestForm'
import {UserModel} from '../../../modules/auth/models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_BUY_REQUEST_URL = `${API_URL}v1/auth/buy-request/`
export const SEARCH_BUY_REQUEST_URL = `${API_URL}v1/auth/filter-buy-request/`
export const CREATE_BUY_REQUEST_URL = `${API_URL}v1/auth/buy-request`
export const UPDATE_BUY_REQUEST_URL = `${API_URL}v1/auth/update-buy-request`
export const DELETE_BUY_REQUEST_URL = `${API_URL}v1/auth/buy-request/`
export const CHANGE_STATUS_BUY_REQUEST_URL = `${API_URL}v1/auth/buy-request/active-status/`

interface authType {
  data: UserModel
}
// Get BuyRequest Data
export async function getBuyRequestData(startIndex: number, limitNumber: number, status?: string) {
  try {
    let getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}${startIndex}/${limitNumber}`
    if (status !== undefined && status !== '') {
      getBuyReqAPIPath = `${GET_BUY_REQUEST_URL}${startIndex}/${limitNumber}/${status}`
    }
    let buyRequestData = await axios.get(getBuyReqAPIPath)
    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Search BuyRequest Data
export async function getSearchBuyRequestData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let buyRequestData = await axios.post(`${SEARCH_BUY_REQUEST_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Create BuyRequest Data
export async function createBuyRequestData(buyRequestObject: IBuyRequestFormValues) {
  try {
    let createBuyRequestData = await axios.post(`${CREATE_BUY_REQUEST_URL}`, {
      ...buyRequestObject,
    })
    return createBuyRequestData
  } catch (e) {
    throw e
  }
}

export async function createBuyRequestDataWithValues(
  buyRequestObject: IBuyRequestFormValues,
  formValues: any
) {
  try {
    let shipping_address: any = {}
    let billing_address: any = {}

    if (formValues) {
      billing_address.address_line_1 = formValues.address_line_1 ? formValues.address_line_1 : ''
      billing_address.address_line_2 = formValues.address_line_2 ? formValues.address_line_2 : ''
      billing_address.city = formValues.city ? formValues.city : ''
      billing_address.state = formValues.state ? formValues.state : ''
      billing_address.country = formValues.country ? formValues.country : ''
      billing_address.postal_code = formValues.postal_code ? formValues.postal_code : ''

      shipping_address.address_line_1 = formValues.shipment_address_line_1
        ? formValues.shipment_address_line_1
        : ''
      shipping_address.address_line_2 = formValues.shipment_address_line_2
        ? formValues.shipment_address_line_2
        : ''
      shipping_address.city = formValues.shipment_city ? formValues.shipment_city : ''
      shipping_address.state = formValues.shipment_state ? formValues.shipment_state : ''
      shipping_address.country = formValues.shipment_country ? formValues.shipment_country : ''
      shipping_address.postal_code = formValues.shipment_postal_code
        ? formValues.shipment_postal_code
        : ''
    }
    console.log(shipping_address, billing_address, 'address')
    let createBuyRequestData: authType = await axios.post(`${CREATE_BUY_REQUEST_URL}`, {
      ...buyRequestObject,
      shipping_address,
      billing_address,
    })
    return {...createBuyRequestData.data, role: 'client'}
  } catch (e) {
    throw e
  }
}

// Delete BuyRequest Data
export async function deleteBuyRequestData(buyRequestId: string) {
  try {
    let deleteBuyRequestData = await axios.delete(`${DELETE_BUY_REQUEST_URL}${buyRequestId}`)
    return deleteBuyRequestData
  } catch (e) {
    throw e
  }
}

// Change Status BuyRequest Data
export async function changeStatusBuyRequestData(buyRequestId: string, flag: boolean) {
  try {
    let buyRequestData = await axios.put(`${CHANGE_STATUS_BUY_REQUEST_URL}`, {
      buyRequestId,
      flag,
    })
    return buyRequestData
  } catch (e) {
    throw e
  }
}

// Edit BuyRequest Data
export async function editBuyRequestData(
  buyRequestObject: IBuyRequestFormValues,
  buyRequestId: string
) {
  try {
    let editBuyRequestData = await axios.put(`${UPDATE_BUY_REQUEST_URL}`, {
      ...buyRequestObject,
      buyRequestId,
    })
    return editBuyRequestData
  } catch (e) {
    throw e
  }
}
