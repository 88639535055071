import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {InputNumberText} from '../../../modules/comman/formComponents/InputNumberText'
import {InputToggle} from '../../../modules/comman/formComponents/InputToggle'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
import {Toaster} from '../../../modules/comman/components/Toaster'

interface IRenderFormikForm {
  values: IBannerFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IBannerFormValues {
  name: string
  description: string
  banner_image: any
  order: number
  route: string
}

interface IShipmentPriceProps {
  onSubmit: any
  data?: any
  handleCancel?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export class CreateBannerForm extends React.Component<IShipmentPriceProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isImageLinked: false,
      image_url: '',
      file_name: '',
    }
  }

  viewFileUploadModalRef: {show: () => void} | undefined

  public validateForm = (values: IBannerFormValues) => {
    const errors: any = {}

    if (!values.name) {
      errors.name = 'Name is Required'
    }

    if (!values.description) {
      errors.description = 'Description is Required'
    }

    if (!values.order) {
      errors.order = 'Order Number is Required'
    }
    if (!values.route) {
      errors.route = 'Route is Required'
    }

    return errors
  }

  public handleSubmit = (values: IBannerFormValues, action: any) => {
    if (this.state.file_name) {
      values.banner_image = this.state.file_name
    } else {
      Toaster({
        type: 'error',
        title: 'Banner',
        description: 'Please Upload Banner Image',
      })
      return
    }
    this.props.onSubmit(values)
    this.props.handleCancel()
    this.setState({
      visible: false,
    })
  }

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
    this.setState({
      viewFileUploadModal: false,
      isImageLinked: false,
      image_url: `${appAPIURL}banner/image/${fileName}`,
      file_name: fileName,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  name: '',
                  description: '',
                  banner_image: '',
                  order: NaN,
                  route: '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div className='table-form'>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.name,
                              id: 'name',
                              name: 'name',
                            }}
                            placeholder='Enter Name'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('name', value)
                            }}
                            onBlur={handleBlur}
                            label='Name'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.description,
                              id: 'description',
                              name: 'description',
                            }}
                            placeholder='Enter Description'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('description', value)
                            }}
                            onBlur={handleBlur}
                            label='Description'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div className='form-group row'>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: values.route,
                              id: 'route',
                              name: 'route',
                            }}
                            placeholder='Enter Route'
                            onChange={(value: string) => {
                              handleChange(value)
                              setFieldValue('route', value)
                            }}
                            onBlur={handleBlur}
                            label='Route'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputNumberText
                            input={{
                              value: values.order,
                              id: 'order',
                              name: 'order',
                            }}
                            placeholder='Enter Order'
                            onChange={(value: number) => {
                              handleChange(value)
                              setFieldValue('order', value)
                            }}
                            onBlur={handleBlur}
                            label='Order'
                            error={errors}
                            touched={touched}
                          />
                        </div>
                      </div>
                      <div>
                        <InputSingleFileUpload
                          ref={(e: any) => {
                            this.viewFileUploadModalRef = e
                          }}
                          //   buttonLabel={'Choose Provider Image'}
                          onBlur={() => {}}
                          error={() => {}}
                          touched={() => {}}
                          //   required={false}
                          //   label='Item Image'
                          name='image_url'
                          input={{
                            id: 'image',
                            name: 'image',
                            action: `${appAPIURL}/file/upload/banner/image`,
                            headers: {
                              authorization: 'authorized-text',
                            },
                            accept: 'png|jpeg|jpg|gif|webp',
                            acceptMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
                            mediaType: 'png|jpeg|jpg|gif|webp',
                            mediaModuleType: 'png|jpeg|jpg|gif|webp',
                          }}
                          onUpload={(
                            fileName: string,
                            FileData: Array<any>,
                            customFileData: Object
                          ) => {
                            this.uploadMedia(fileName, FileData, customFileData)
                          }}
                        />
                      </div>
                      {console.log(this.state.image_url)}
                      {this.state.image_url ? (
                        <img
                          src={`${this.state.image_url}`}
                          alt='Banner'
                          style={{width: '350px', height: '175px', margin: '10px auto'}}
                        />
                      ) : null}
                      <div style={{marginBottom: '15px'}}></div>
                      <InputButtonComponent onClick={handleSubmit}>Create</InputButtonComponent>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
          {/* <div className='card-footer'></div> */}
        </div>
      </div>
    )
  }
}
