import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import moment from 'moment'
import {Tag, Alert, Button} from 'antd'
import {config} from '../../../comman/constants'
import {
  returnPriceInThousandFormat,
  returnPriceInThousandFormatWithCurrencySign,
  returnStockId,
  returnStockString,
} from '../../../comman/helper'
import {getShipMentPriceList} from '../../shipmentPrice/redux/ShipmentPriceCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {Toaster} from '../../../modules/comman/components/Toaster'
import {editOrderStatusData} from '../redux/OrderCRUD'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputSingleFileUpload} from '../../../modules/comman/formComponents/InputSingleFileUpload'
interface IPreviewOrderProps {
  data: any
  handleCancel?: any
  loginUserData?: any
}

const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export class PreviewOrderForm extends React.Component<IPreviewOrderProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      orderStatusState: '',
      failureReasonState: '',
      showErrorFailureReason: false,
      showError: false,
      viewFileUploadModal: false,
      uploadMedia: [],
      isFileLinked: false,
      file_url: '',
      orderStatusData: [
        {
          label: 'Paid',
          value: 'PAID',
        },
        {
          label: 'Failed',
          value: 'FAILED',
        },
      ],
    }
  }

  totalPrice = 0
  totalDiscount = 0
  shipMentPrice = 0

  viewFileUploadModalRef: {show: () => void} | undefined

  public uploadMedia = (fileName: string, FileData: Array<any>, customFileData: Object) => {
    if (fileName && FileData.length >= 1) {
      this.setState({
        uploadMedia: FileData,
      })
      this.setState({
        viewFileUploadModal: false,
        isFileLinked: false,
        // file_url: `${appAPIURL}order/invoice/${fileName}`,
        file_url: fileName,
      })
      Toaster({
        type: 'success',
        title: 'Order',
        description: 'Invoice Uploaded Successfully',
      })
      console.log('!!!!!!!!!!!uploadMedia')
    }
    //close modal popup
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  componentDidMount() {
    this.callInitialData()
  }

  // call initial data for Form
  public callInitialData = async () => {
    try {
      let shipMentList = await getShipMentPriceList()
      if (shipMentList.data) {
        this.setState({shipmentData: shipMentList.data})
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  public calculateTotal = () => {
    const {data} = this.props
    if (
      data &&
      data.buy_request_id.stock_diamonds &&
      data.buy_request_id.stock_diamonds.length >= 1
    ) {
      this.totalPrice = 0
      this.totalDiscount = 0
      data.buy_request_id.stock_diamonds.map((invItem: any) => {
        this.totalPrice += invItem.stock_id.final_price
        this.totalDiscount += invItem.stock_id.discounts
      })
    }
    return returnPriceInThousandFormatWithCurrencySign(this.totalPrice)
  }

  public calculateAvgDiscount = () => {
    const {data} = this.props
    return returnPriceInThousandFormat(
      this.totalDiscount / data.buy_request_id.stock_diamonds.length
    )
  }

  public calculateShipMent = () => {
    const {shipmentData} = this.state

    if (shipmentData && shipmentData.length >= 1) {
      let getShipMentData = shipmentData.find(
        (shipItem: any) =>
          shipItem.from_number < this.totalPrice && shipItem.to_number > this.totalPrice
      )
      if (getShipMentData) {
        this.shipMentPrice = getShipMentData.price
      }
    }
    return returnPriceInThousandFormatWithCurrencySign(this.shipMentPrice)
  }

  public calculateGrandTotal = () => {
    let grandTotal = this.shipMentPrice + this.totalPrice
    return returnPriceInThousandFormatWithCurrencySign(grandTotal)
  }

  public handleEditOrderSubmit = async () => {
    try {
      if (!this.state.orderStatusState || this.state.orderStatusState == '') {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Select Order Status',
        })
        this.setState({
          showError: true,
        })
        return
      } else if (
        this.state.orderStatusState == 'FAILED' &&
        (!this.state.failureReasonState || this.state.failureReasonState == '')
      ) {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Enter Failure Reason',
        })
        this.setState({
          showErrorFailureReason: true,
        })
        return
      } else if (
        this.state.orderStatusState == 'PAID' &&
        (!this.state.file_url || this.state.file_url == '')
      ) {
        Toaster({
          type: 'error',
          title: 'Order',
          description: 'Please Upload Invoice',
        })
        return
      }
      await editOrderStatusData(
        this.props.data._id,
        this.state.orderStatusState,
        this.state.failureReasonState,
        this.state.file_url
      )
      Toaster({
        type: 'success',
        title: 'Order',
        description: 'Order Status changed successFully',
      })
      this.props.handleCancel()
    } catch (e) {
      throw e
    }
  }

  public render() {
    const {data, loginUserData} = this.props

    return (
      <div className='card card-custom'>
        {data ? (
          <>
            <div className='alert alert-primary text-center font-weight-bold' role='alert'>
              {'Please check your mail for further process'}
            </div>
            <div className='card-body'>
              <div>
                <div className='row mb-10'>
                  <div className='col-md-6 col-lg-6 col-sm-12'>
                    <h3>
                      {'Order Details'}
                      {` (${data.order_code && returnStockId(data.order_code)})`}
                    </h3>
                  </div>
                  <div className='col-md-3 col-lg-3 col-sm-12'>
                    <strong>
                      {data?.status && (
                        <Tag
                          className='p-2'
                          color={
                            config.statusTagColor[data.status]
                              ? config.statusTagColor[data.status]
                              : 'default'
                          }
                        >
                          {data.status}
                        </Tag>
                      )}
                    </strong>
                  </div>
                  <div className='col-md-3 col-lg-3 col-sm-12'>
                    <strong>
                      {data.order_date ? moment(data.order_date).format('DD-MM-YYYY') : '-'}
                    </strong>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='row'>
                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label>{'Order Code:'}</label>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                      <label>{data.order_code ? returnStockId(data.order_code) : '-'}</label>
                    </div>

                    <div className='col-md-2 col-lg-2 col-sm-12 font-weight-bold text-muted'>
                      <label>{'Order Date:'}</label>
                    </div>
                    <div className='col-md-4 col-lg-4 col-sm-12'>
                      <label>
                        {data.order_date ? moment(data.order_date).format('DD-MM-YYYY') : '-'}
                      </label>
                    </div>
                  </div>
                  {loginUserData?.role === 'admin' && (
                    <>
                      <div className='row'>
                        <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                          <label>{'Name:'}</label>
                        </div>
                        <div className='col-lg-4'>
                          <label>
                            {data?.client_id?.first_name && data?.client_id?.last_name
                              ? `${data.client_id.first_name} ${data.client_id.last_name}`
                              : data.client_id.first_name
                              ? data.client_id.first_name
                              : '-'}
                          </label>
                        </div>
                        <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                          <label>{'Company:'}</label>
                        </div>
                        <div className='col-lg-4'>
                          <label>
                            {data?.client_id?.company_name ? data.client_id.company_name : '-'}
                          </label>
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-md-2 col-lg-2 text-dark font-weight-bold text-muted'>
                          <label>{'Email:'}</label>
                        </div>
                        <div className='col-lg-4'>
                          <label>{data?.client_id?.email ? data.client_id.email : '-'}</label>
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <div className='row border-bottom'>
                  <h3 className='mb-10'>{'Stock Details'}</h3>
                  <div className='col-lg-2 text-dark font-weight-bold text-muted'>{'Stock Id'}</div>
                  <div className='col-lg-5 text-dark font-weight-bold text-muted'>{'Details'}</div>
                  <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                    <div className='text-right'>
                      <label>{'Discount'}</label>
                    </div>
                  </div>
                  <div className='col-lg-1 text-dark font-weight-bold text-muted'>
                    <div className='text-right'>
                      <label>{'$/Ct'}</label>
                    </div>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold text-muted'>
                    <div className='text-right'>
                      <label>{'Price'}</label>
                    </div>
                  </div>
                </div>
                <div style={{maxHeight: '70vh', overflowX: 'hidden', overflowY: 'auto'}}>
                  {data.buy_request_id.stock_diamonds.map(
                    (invItem: any, index: number) =>
                      invItem && (
                        <div className='row mt-10' key={index}>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <label>
                              <a
                                href={`/inventory/preview/${invItem.stock_id._id}`}
                                target='_blank'
                              >
                                {returnStockId(invItem.stock_id.stock_id)}
                              </a>
                            </label>
                          </div>
                          <div className='col-lg-5 text-dark font-weight-bold'>
                            <label>{returnStockString(invItem.stock_id)}</label>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>{invItem.stock_id.discounts}</label>
                            </div>
                          </div>
                          <div className='col-lg-1 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>{invItem.stock_id.price_per_caret}</label>
                            </div>
                          </div>
                          <div className='col-lg-2 text-dark font-weight-bold'>
                            <div className='text-right'>
                              <label>
                                {returnPriceInThousandFormatWithCurrencySign(
                                  invItem.stock_id.final_price
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                      )
                  )}
                </div>
                <div className='row border-top mt-10'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Sub Total:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{this.calculateTotal()} </label>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Discount:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{`${this.calculateAvgDiscount()}(%)`} </label>
                    </div>
                  </div>
                </div>
                <div className='row'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Shipment Price:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{this.calculateShipMent()} </label>
                    </div>
                  </div>
                </div>
                <div className='row border-bottom'>
                  <div className='col-lg-10 text-dark font-weight-bold text-muted text-end'>
                    <label>{'Total:'}</label>
                  </div>
                  <div className='col-lg-2 text-dark font-weight-bold'>
                    <div className='text-right'>
                      <label>{this.calculateGrandTotal()} </label>
                    </div>
                  </div>
                </div>
                <div className='row mt-6'>
                  <div className='col-lg-5 col-md-5 col-sm-12 mb-10 ml-2 text-muted'>
                    <h3 className='mb-2'>{'Billing Address'}</h3>
                    <span>
                      {data?.buy_request_id && data?.buy_request_id.billing_address && (
                        <span
                          className='text-dark font-weight-normal text-muted'
                          style={{fontSize: '16px'}}
                        >
                          {data?.buy_request_id?.billing_address.address_line_1
                            ? ` ${data?.buy_request_id?.billing_address.address_line_1},`
                            : ''}
                          {data?.buy_request_id?.billing_address.address_line_2
                            ? ` ${data?.buy_request_id?.billing_address.address_line_2},`
                            : ''}
                          {data?.buy_request_id?.billing_address.city
                            ? ` ${data?.buy_request_id?.billing_address.city},`
                            : ''}
                          {data?.buy_request_id?.billing_address.state
                            ? ` ${data?.buy_request_id?.billing_address.state},`
                            : ''}
                          {data?.buy_request_id?.billing_address.country
                            ? ` ${data?.buy_request_id?.billing_address.country} -`
                            : ''}
                          {data?.buy_request_id?.billing_address.postal_code
                            ? ` ${data?.buy_request_id?.billing_address.postal_code}`
                            : ''}
                        </span>
                      )}
                    </span>
                  </div>
                  <div className='col-lg-5 col-md-5 col-sm-12 mb-10'>
                    <h3 className='mb-2'>{'Shipping Address'}</h3>
                    <span>
                      {data?.buy_request_id && data?.buy_request_id.shipping_address && (
                        <div
                          className='text-dark font-weight-normal text-muted'
                          style={{fontSize: '16px'}}
                        >
                          {data?.buy_request_id?.shipping_address.address_line_1
                            ? ` ${data?.buy_request_id?.shipping_address.address_line_1},`
                            : ''}
                          {data?.buy_request_id?.shipping_address.address_line_2
                            ? ` ${data?.buy_request_id?.shipping_address.address_line_2},`
                            : ''}
                          {data?.buy_request_id?.shipping_address.city
                            ? ` ${data?.buy_request_id?.shipping_address.city},`
                            : ''}
                          {data?.buy_request_id?.shipping_address.state
                            ? ` ${data?.buy_request_id?.shipping_address.state},`
                            : ''}
                          {data?.buy_request_id?.shipping_address.country
                            ? ` ${data?.buy_request_id?.shipping_address.country} -`
                            : ''}
                          {data?.buy_request_id?.shipping_address.postal_code
                            ? ` ${data?.buy_request_id?.shipping_address.postal_code}`
                            : ''}
                        </div>
                      )}
                    </span>
                  </div>
                </div>
                <hr></hr>
                {this.props.data?.status == 'PENDING' && loginUserData?.role === 'admin' && (
                  <>
                    <div className='row mt-3 mb-3'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <h4>{'Order Status'}</h4>
                      </div>
                    </div>
                    <div className='row mt-3 mb-3'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <InputSingleSelect
                          input={{
                            value: this.state.orderStatusState,
                            id: 'order_status',
                            name: 'order_status',
                            options: this.state.orderStatusData,
                          }}
                          placeholder='Select Order Status'
                          onChangeMethod={(value: any) => {
                            this.setState({
                              orderStatusState: value,
                            })
                            this.setState({
                              showError: false,
                            })
                          }}
                          label='Select Order Status'
                          error={() => {}}
                          touched={() => {}}
                        />
                        {this.state.showError && (
                          <p style={{color: 'red', marginTop: '10px', marginBottom: '10px'}}>
                            Please Select Order Status
                          </p>
                        )}
                      </div>
                      {this.state.orderStatusState == 'FAILED' && (
                        <div className='col-md-6 col-lg-6 col-sm-12'>
                          <InputText
                            input={{
                              value: this.state.failureReasonState,
                              id: 'failure_reason',
                              name: 'failure_reason',
                            }}
                            placeholder='Enter Failure Reason'
                            onChange={(value: string) => {
                              this.setState({
                                failureReasonState: value,
                              })
                            }}
                            onBlur={() => {}}
                            label='Failure Reason'
                            error={() => {}}
                            touched={() => {}}
                          />
                          {this.state.showErrorFailureReason && (
                            <p style={{color: 'red', marginTop: '10px', marginBottom: '10px'}}>
                              Please Enter Failure Reason
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                )}
                {this.props.data.failure_reason ? (
                  <div style={{marginBottom: '10px'}}>
                    <div style={{margin: '10', alignItems: 'center'}}>
                      <Alert message={this.props.data.failure_reason} type='error' showIcon />
                    </div>
                  </div>
                ) : null}
                {this.props.data.attached_file_url ? (
                  <div>
                    {this.props.data.attached_file_url &&
                      this.props.data.attached_file_url.split('.')[
                        this.props.data.attached_file_url.split('.').length - 1
                      ] === 'pdf' && (
                        <div style={{margin: '10', alignItems: 'center'}}>
                          <Alert message='Click To Download Attachment' type='success' showIcon />
                          <Button>
                            <a href={this.props.data.attached_file_url} target='_blank'>
                              Click to Download
                            </a>
                          </Button>
                        </div>
                      )}
                    {((this.props.data.attached_file_url &&
                      this.props.data.attached_file_url.split('.')[
                        this.props.data.attached_file_url.split('.').length - 1
                      ] === 'jpg') ||
                      (this.props.data.attached_file_url &&
                        this.props.data.attached_file_url.split('.')[
                          this.props.data.attached_file_url.split('.').length - 1
                        ] === 'jpeg') ||
                      (this.props.data.attached_file_url &&
                        this.props.data.attached_file_url.split('.')[
                          this.props.data.attached_file_url.split('.').length - 1
                        ] === 'png')) && (
                      <img
                        src={this.props.data.attached_file_url}
                        alt='Logo'
                        style={{width: '350px', height: '275px', margin: '20px auto'}}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO ATTACHMENTS FOUND
                  </div>
                )}

                {this.props.data.invoice_file_url ? (
                  <div style={{marginTop: '10px'}}>
                    {this.props.data.invoice_file_url &&
                      this.props.data.invoice_file_url.split('.')[
                        this.props.data.invoice_file_url.split('.').length - 1
                      ] === 'pdf' && (
                        <div style={{margin: '10', alignItems: 'center'}}>
                          <Alert message='Click To Download Invoice' type='success' showIcon />
                          <Button>
                            <a href={this.props.data.invoice_file_url} target='_blank'>
                              Click to Download
                            </a>
                          </Button>
                        </div>
                      )}
                    {console.log(
                      this.props.data.invoice_file_url &&
                        this.props.data.invoice_file_url.split('.')[
                          this.props.data.invoice_file_url.split('.').length - 1
                        ]
                    )}
                    {((this.props.data.invoice_file_url &&
                      this.props.data.invoice_file_url.split('.')[
                        this.props.data.invoice_file_url.split('.').length - 1
                      ] === 'jpg') ||
                      (this.props.data.invoice_file_url &&
                        this.props.data.invoice_file_url.split('.')[
                          this.props.data.invoice_file_url.split('.').length - 1
                        ] === 'jpeg') ||
                      (this.props.data.invoice_file_url &&
                        this.props.data.invoice_file_url.split('.')[
                          this.props.data.invoice_file_url.split('.').length - 1
                        ] === 'png')) && (
                      <img
                        src={this.props.data.invoice_file_url}
                        alt='Logo'
                        style={{width: '350px', height: '275px', margin: '20px auto'}}
                      />
                    )}
                  </div>
                ) : (
                  <div
                    style={{marginTop: '10px'}}
                    className='alert alert-danger text-center font-weight-bold'
                    role='alert'
                  >
                    NO ATTACHMENTS FOR INVOICE FOUND
                  </div>
                )}
                {this.state.orderStatusState == 'PAID' && loginUserData?.role === 'admin' && (
                  <>
                    <div className='row mt-3 mb-3'>
                      <div className='col-md-6 col-lg-6 col-sm-12'>
                        <h4>{'Upload Invoice'}</h4>
                      </div>
                    </div>
                    <div style={{marginTop: '15px', marginBottom: '15px'}}>
                      <InputSingleFileUpload
                        ref={(e: any) => {
                          this.viewFileUploadModalRef = e
                        }}
                        onBlur={() => {}}
                        error={() => {}}
                        touched={() => {}}
                        name='file'
                        input={{
                          id: 'invoice',
                          name: 'invoice',
                          action: `${appAPIURL}file/upload/order/invoice`,
                          headers: {
                            authorization: 'authorized-text',
                          },
                          accept: 'pdf|png|jpeg|jpg|gif|webp',
                          acceptMimeTypes: [
                            'image/png',
                            'image/jpeg',
                            'image/jpg',
                            'application/pdf',
                          ],
                          mediaType: 'pdf|png|jpeg|jpg|gif|webp',
                          mediaModuleType: 'pdf|png|jpeg|jpg|gif|webp',
                        }}
                        onUpload={(
                          fileName: string,
                          FileData: Array<any>,
                          customFileData: Object
                        ) => {
                          this.uploadMedia(fileName, FileData, customFileData)
                        }}
                      />
                    </div>
                    {this.state.file_url ? (
                      // <img
                      //   src={`${this.state.file_url}`}
                      //   alt='Invoice'
                      //   style={{width: '350px', height: '75px', margin: '10px auto'}}
                      // />
                      <div>
                        {this.state.file_url &&
                          this.state.file_url.split('.')[
                            this.state.file_url.split('.').length - 1
                          ] === 'pdf' && (
                            <div style={{margin: '10%', alignItems: 'center'}}>
                              <Alert message='Click To Download Pdf' type='success' showIcon />
                              <Button style={{marginTop: '10px'}}>
                                <a href={this.state.file_url} target='_blank'>
                                  Click to Download Pdf
                                </a>
                              </Button>
                            </div>
                          )}
                        {console.log(
                          this.state.file_url &&
                            this.state.file_url.split('.')[
                              this.state.file_url.split('.').length - 1
                            ]
                        )}
                        {((this.state.file_url &&
                          this.state.file_url.split('.')[
                            this.state.file_url.split('.').length - 1
                          ] === 'jpg') ||
                          (this.state.file_url &&
                            this.state.file_url.split('.')[
                              this.state.file_url.split('.').length - 1
                            ] === 'jpeg') ||
                          (this.state.file_url &&
                            this.state.file_url.split('.')[
                              this.state.file_url.split('.').length - 1
                            ] === 'png')) && (
                          <img
                            src={this.state.file_url}
                            alt='Logo'
                            style={{width: '350px', height: '275px', margin: '20px auto'}}
                          />
                        )}
                      </div>
                    ) : null}
                  </>
                )}
                {this.props.data?.status == 'PENDING' && loginUserData?.role === 'admin' && (
                  <div>
                    <div className='float-right'>
                      <InputButtonComponent onClick={this.handleEditOrderSubmit}>
                        Save
                      </InputButtonComponent>
                    </div>
                  </div>
                )}
                {/* <div className='row border-bottom'>
                  <div className='col-lg-6 font-weight-bold text-muted'>{'Stock Id'}</div>
                  <div className='col-lg-6 font-weight-bold text-muted'>
                    <div className='w-25 text-right'>{'Price'}</div>
                  </div>
                </div> */}
                {/* {data.buy_request_id && data.buy_request_id.stock_diamonds ? (
                  <div className='overflow-auto' style={{maxHeight: '300px'}}>
                    {data.buy_request_id.stock_diamonds.map((stockItem: any, index: number) => (
                      <div className='row mt-10' key={index}>
                        <div className='col-lg-6 text-dark font-weight-bold'>
                          <label>
                            <a
                              href={`/inventory/preview/${stockItem.stock_id._id}`}
                              target='_blank'
                            >
                              {returnStockId(stockItem.stock_id.stock_id)}
                            </a>
                          </label>
                        </div>
                        <div className='col-lg-6 text-dark font-weight-bold'>
                          <div className='w-25 text-right'>
                            <label>
                              {(Math.round(stockItem.stock_id.final_price * 100) / 100).toFixed(2)}
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  '-'
                )}{' '} */}
              </div>
            </div>
          </>
        ) : (
          'Please wait'
        )}
      </div>
    )
  }
}
