import React from 'react'
import axios from 'axios'
import {ClientModal} from '../models/ClientModal'
import {IClientFormValues} from '../containers/CreateClientForm'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'
export const GET_CLIENT_URL = `${API_URL}v1/auth/client/`
export const SEARCH_CLIENT_URL = `${API_URL}v1/auth/filter-client/`
export const CREATE_CLIENT_URL = `${API_URL}v1/auth/client`
export const EDIT_CLIENT_URL = `${API_URL}v1/auth/client/`
export const DELETE_CLIENT_URL = `${API_URL}v1/auth/client/`
export const CHANGE_STATUS_CLIENT_URL = `${API_URL}v1/auth/client/active-status/`
export const CHANGE_VERIFY_STATUS_CLIENT_URL = `${API_URL}v1/auth/client/verify-status/`


// Get Client Data
export async function getClientData(startIndex: number, limitNumber: number) {
  try {
    let clientData = await axios.get(`${GET_CLIENT_URL}${startIndex}/${limitNumber}`)
    return clientData
  } catch (e) {
    throw e
  }
}

// Search Client Data
export async function getSearchClientData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let clientData = await axios.post(`${SEARCH_CLIENT_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return clientData
  } catch (e) {
    throw e
  }
}

// Create Client Data
export async function createClientData(clientObject: IClientFormValues) {
  try {
    let createClientData = await axios.post(`${CREATE_CLIENT_URL}`, {
      ...clientObject,
    })
    return createClientData
  } catch (e) {
    throw e
  }
}

// Delete Client Data
export async function deleteClientData(clientId: string) {
  try {
    let deleteClientData = await axios.delete(`${DELETE_CLIENT_URL}${clientId}`)
    return deleteClientData
  } catch (e) {
    throw e
  }
}

// Change Status Client Data
export async function changeStatusClientData(clientId: string, flag: boolean) {
  try {
    let clientData = await axios.put(`${CHANGE_STATUS_CLIENT_URL}`, {
      clientId,
      flag,
    })
    return clientData
  } catch (e) {
    throw e
  }
}

export async function changeVerifyStatusClientData(clientId: string, flag: boolean) {
  try {
    let clientData = await axios.put(`${CHANGE_VERIFY_STATUS_CLIENT_URL}`, {
      clientId,
      flag,
    })
    return clientData
  } catch (e) {
    throw e
  }
}

// Edit Client Data
export async function editClientData(clientObject: IClientFormValues, clientId: string) {
  try {
    let editClientData = await axios.put(`${EDIT_CLIENT_URL}`, {
      ...clientObject,
      clientId,
    })
    return editClientData
  } catch (e) {
    throw e
  }
}
