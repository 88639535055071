const appAPIURL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

type ConfigType = {
  title: string
  buttonPrimaryColor: string
  buttonTextPrimaryColor: string
  videoExtensions: string
  videoExtensionsForDropZone: string
  videoExtensionsMimeTypes: Array<string>
  audioExtensions: string
  audioExtensionsForDropZone: string
  audioExtensionsMimeTypes: Array<string>
  imageExtensions: string
  imageExtensionsForDropZone: string
  imageExtensionsMimeTypes: Array<string>
  fileExtensions: string
  fileExtensionsForDropZone: string
  fileExtensionsMimeTypes: Array<string>
  importStockUploadKey: string
  importStockUploadPath: string
  importStockPreviewPath: string
  inventorySearchParams: Array<{
    value: string
    label: string
    index: number
  }>
  inventorySearchParamsValue: Array<any>
  statusTagColor: any
  matrixTableClarity: any
  matrixTableColor: any
  downloadSampleFilePath: string
}

export const config: ConfigType = {
  title: 'Xazina',
  buttonPrimaryColor: '#009ef7',
  buttonTextPrimaryColor: '#fff',
  videoExtensions: 'mpg|mp2|mpeg|mpe|mpv|mp4|m4p|m4v|mkv|flv|vob|svi|ogg|ogv|mov',
  videoExtensionsForDropZone:
    '.mpg,.mp2,.mpeg,.mpe,.mpv,.mp4,.m4p,.m4v,.mkv,.flv,.vob,.svi,.ogg,.ogv,.mov',
  videoExtensionsMimeTypes: ['video/mp4', 'video/api'],
  audioExtensions: 'mp3|acc|ogg|m4a',
  audioExtensionsForDropZone: '.mp3,.acc,.ogg,.m4a',
  audioExtensionsMimeTypes: ['audio/mp3', 'audio/avi'],
  imageExtensions: 'png|jpeg|jpg|gif|webp',
  imageExtensionsForDropZone: '.png,.jpeg,.jpg,.gif,.webp',
  imageExtensionsMimeTypes: ['image/png', 'image/jpeg', 'image/jpg'],
  fileExtensions: 'csv|xlsx|xls|xlsm',
  fileExtensionsForDropZone: '.csv,.xlsx,.xls',
  fileExtensionsMimeTypes: [
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-excel',
  ],
  importStockUploadKey: 'excel',
  importStockUploadPath: appAPIURL + 'file/upload/stock/excel',
  importStockPreviewPath: appAPIURL + 'stock/excel/',
  downloadSampleFilePath: appAPIURL + 'excel/Template.xlsx',
  inventorySearchParams: [
    {value: 'shape', label: 'Shape', index: 0},
    {value: 'size', label: 'Size', index: 1},
    {value: 'white_color', label: 'White color', index: 2},
    {value: 'fancy_color', label: 'Fancy color', index: 3},
    {value: 'clarity', label: 'Clarity', index: 4},
    {value: 'lab', label: 'Lab', index: 5},
    {value: 'cut', label: 'Cut', index: 6},
    {value: 'polish', label: 'Polish', index: 7},
    {value: 'symmetry', label: 'Symmetry', index: 8},
    {value: 'fluorescence_intensity', label: 'Fluorescence Intensity', index: 9},
    // {value: 'Report', label: 'report', index: 10},
  ],
  inventorySearchParamsValue: [
    [
      // {
      //   label: 'All',
      //   value: 'all',
      //   icon: 'round',
      // },
      {
        label: 'Round',
        value: 'round',
        icon: 'round',
      },
      {
        label: 'Princess',
        value: 'princess',
        icon: 'princess',
      },
      {
        label: 'Asscher',
        value: 'asscher',
        icon: 'asscher',
      },
      {
        label: 'Cushion',
        value: 'cushion',
        icon: 'cushion',
      },
      {
        label: 'Emerald',
        value: 'emerald',
        icon: 'emerald',
      },
      {
        label: 'Heart',
        value: 'heart',
        icon: 'heart',
      },
      {
        label: 'Marquise',
        value: 'marquise',
        icon: 'marquise',
      },
      {
        label: 'Oval',
        value: 'oval',
        icon: 'oval',
      },
      {
        label: 'Pear',
        value: 'pear',
        icon: 'pear',
      },
      {
        label: 'Radiant',
        value: 'radiant',
        icon: 'radiant',
      },
      {
        label: 'Trilliant',
        value: 'trilliant',
        icon: 'trilliant',
      },
      {
        label: 'Other',
        value: 'other',
        icon: 'other',
      },
    ],
    [
      {
        label: '0.30-0.39',
        value: '0.30-0.39',
        icon: 'size',
      },
      {
        label: '0.40-0.49',
        value: '0.40-0.49',
        icon: 'size',
      },
      {
        label: '0.50-0.59',
        value: '0.50-0.59',
        icon: 'size',
      },
      {
        label: '0.70-0.89',
        value: '0.70-0.89',
        icon: 'size',
      },
      {
        label: '0.90-0.99',
        value: '0.90-0.99',
        icon: 'size',
      },
      {
        label: '1.00-1.49',
        value: '1.00-1.49',
        icon: 'size',
      },
      {
        label: '1.50-1.99',
        value: '1.50-1.99',
        icon: 'size',
      },
      {
        label: '2.00-2.99',
        value: '2.00-2.99',
        icon: 'size',
      },
      {
        label: '3.00-3.99',
        value: '3.00-3.99',
        icon: 'size',
      },
      {
        label: '4.00-4.99',
        value: '4.00-4.99',
        icon: 'size',
      },
      {
        label: '5.00-5.99',
        value: '5.00-5.99',
        icon: 'size',
      },
      {
        label: '10.00-10.99',
        value: '10.00-10.99',
        icon: 'size',
      },
    ],
    [
      {
        label: 'D',
        value: 'd',
        icon: 'd',
      },
      {
        label: 'E',
        value: 'e',
        icon: 'e',
      },
      {
        label: 'F',
        value: 'f',
        icon: 'f',
      },
      {
        label: 'G',
        value: 'g',
        icon: 'g',
      },
      {
        label: 'H',
        value: 'h',
        icon: 'g',
      },
      {
        label: 'I',
        value: 'i',
        icon: 'g',
      },
      {
        label: 'J',
        value: 'j',
        icon: 'g',
      },
      {
        label: 'K',
        value: 'k',
        icon: 'g',
      },
      {
        label: 'L',
        value: 'l',
        icon: 'g',
      },
      {
        label: 'M',
        value: 'm',
        icon: 'g',
      },
      {
        label: 'M+',
        value: 'm+',
        icon: 'g',
      },
      // {
      //   label: 'N',
      //   value: 'n',
      //   icon: 'g',
      // },
      // {
      //   label: 'O',
      //   value: 'o',
      //   icon: 'g',
      // },
      // {
      //   label: 'P',
      //   value: 'p',
      //   icon: 'g',
      // },
      // {
      //   label: 'Q',
      //   value: 'q',
      //   icon: 'g',
      // },
      // {
      //   label: 'R',
      //   value: 'r',
      //   icon: 'g',
      // },
      // {
      //   label: 'S',
      //   value: 's',
      //   icon: 'g',
      // },
      // {
      //   label: 'T',
      //   value: 't',
      //   icon: 'g',
      // },
      // {
      //   label: 'U',
      //   value: 'u',
      //   icon: 'g',
      // },
      // {
      //   label: 'V',
      //   value: 'v',
      //   icon: 'g',
      // },
      // {
      //   label: 'W',
      //   value: 'w',
      //   icon: 'g',
      // },
      // {
      //   label: 'X',
      //   value: 'x',
      //   icon: 'g',
      // },
      // {
      //   label: 'Y',
      //   value: 'y',
      //   icon: 'g',
      // },
    ],
    [
      {
        label: 'Yellow',
        value: 'yellow',
        icon: 'blank',
      },
      {
        label: 'Pink',
        value: 'pink',
        icon: 'white',
      },
      {
        label: 'Blue',
        value: 'blue',
        icon: 'pink',
      },
      {
        label: 'Red',
        value: 'red',
        icon: 'red',
      },
      {
        label: 'Green',
        value: 'green',
        icon: 'red',
      },
      {
        label: 'Purple',
        value: 'purple',
        icon: 'red',
      },
      {
        label: 'Orange',
        value: 'orange',
        icon: 'red',
      },
      {
        label: 'Violet',
        value: 'violet',
        icon: 'red',
      },
      {
        label: 'Grey',
        value: 'grey',
        icon: 'red',
      },
      {
        label: 'Black',
        value: 'black',
        icon: 'red',
      },
      {
        label: 'Brown',
        value: 'brown',
        icon: 'red',
      },
      {
        label: 'White',
        value: 'white',
        icon: 'red',
      },
      {
        label: 'Champagne',
        value: 'champagne',
        icon: 'red',
      },
      {
        label: 'Cognac',
        value: 'cognac',
        icon: 'red',
      },
      {
        label: 'Chameleon',
        value: 'chameleon',
        icon: 'red',
      },
      {
        label: 'Others',
        value: 'others',
        icon: 'red',
      },
    ],
    [
      {
        label: 'FL',
        value: 'fl',
        icon: 'round',
      },
      {
        label: 'If',
        value: 'if',
        icon: 'pear',
      },
      {
        label: 'VVS1',
        value: 'vvs1',
        icon: 'oval',
      },
      {
        label: 'VVS2',
        value: 'vvs2',
        icon: 'header',
      },
      {
        label: 'VS1',
        value: 'vs1',
        icon: 'header',
      },
      {
        label: 'VS2',
        value: 'vs2',
        icon: 'header',
      },
      {
        label: 'SI1',
        value: 'si1',
        icon: 'header',
      },
      {
        label: 'SI2',
        value: 'si2',
        icon: 'header',
      },
      {
        label: 'I1',
        value: 'i1',
        icon: 'header',
      },
      {
        label: 'I2',
        value: 'i2',
        icon: 'header',
      },
      {
        label: 'I3',
        value: 'i3',
        icon: 'header',
      },
    ],
    [
      {
        label: 'None Certified',
        value: 'none',
        icon: 'round',
      },
      {
        label: 'GIA',
        value: 'gia',
        icon: 'pear',
      },
      {
        label: 'IGI',
        value: 'igi',
        icon: 'oval',
      },
      {
        label: 'HRD',
        value: 'hrd',
        icon: 'header',
      },
      // {
      //   label: 'AGS',
      //   value: 'ags',
      //   icon: 'header',
      // },
      // {
      //   label: 'GCAL',
      //   value: 'gcal',
      //   icon: 'header',
      // },
    ],
    [
      {
        label: 'ID',
        value: 'id',
        icon: 'round',
      },
      {
        label: 'EX',
        value: 'ex',
        icon: 'pear',
      },
      {
        label: 'VG',
        value: 'vg',
        icon: 'oval',
      },
      {
        label: 'GD',
        value: 'gd',
        icon: 'header',
      },
      {
        label: 'FR',
        value: 'fr',
        icon: 'header',
      },
      {
        label: 'PR',
        value: 'pr',
        icon: 'header',
      },
    ],
    [
      {
        label: 'EX',
        value: 'ex',
        icon: 'pear',
      },
      {
        label: 'VG',
        value: 'vg',
        icon: 'oval',
      },
      {
        label: 'GD',
        value: 'gd',
        icon: 'header',
      },
      {
        label: 'FR',
        value: 'fr',
        icon: 'header',
      },
      {
        label: 'PR',
        value: 'pr',
        icon: 'header',
      },
    ],
    [
      {
        label: 'EX',
        value: 'ex',
        icon: 'pear',
      },
      {
        label: 'VG',
        value: 'vg',
        icon: 'oval',
      },
      {
        label: 'GD',
        value: 'gd',
        icon: 'header',
      },
      {
        label: 'FR',
        value: 'fr',
        icon: 'header',
      },
      {
        label: 'PR',
        value: 'pr',
        icon: 'header',
      },
    ],
    [
      {
        label: 'None',
        value: 'none',
        icon: 'pear',
      },
      {
        label: 'Faint',
        value: 'faint',
        icon: 'oval',
      },
      {
        label: 'V.Slight',
        value: 'v-slight',
        icon: 'header',
      },
      {
        label: 'Slight',
        value: 'slight',
        icon: 'header',
      },
      {
        label: 'Medium',
        value: 'medium',
        icon: 'header',
      },
      {
        label: 'Strong',
        value: 'strong',
        icon: 'header',
      },
      {
        label: 'V.Strong',
        value: 'v-strong',
        icon: 'header',
      },
    ],
    [
      {
        label: 'Available',
        value: 'available',
        icon: 'pear',
      },
      {
        label: 'Hold',
        value: 'hold',
        icon: 'oval',
      },
      {
        label: 'Memo',
        value: 'memo',
        icon: 'memo',
      },
    ],
  ],
  statusTagColor: {
    AVAILABLE: 'success',
    HOLD: 'warning',
    SOLD: 'error',
    PENDING: 'processing',
    UPDATED: 'success',
    ACCEPTED: 'success',
    PAID: 'success',
    FAILED: 'error',
  },
  matrixTableClarity: [
    {
      label: 'FL',
      value: 'fl',
    },
    {
      label: 'If',
      value: 'if',
    },
    {
      label: 'VVS1',
      value: 'vvs1',
    },
    {
      label: 'VVS2',
      value: 'vvs2',
    },
    {
      label: 'VS1',
      value: 'vs1',
    },
    {
      label: 'VS2',
      value: 'vs2',
    },
    {
      label: 'SI1',
      value: 'si1',
    },
    {
      label: 'SI2',
      value: 'si2',
    },
    {
      label: 'I1',
      value: 'i1',
    },
    {
      label: 'I2',
      value: 'i2',
    },
    {
      label: 'I3',
      value: 'i3',
    },
  ],
  matrixTableColor: [
    {
      label: 'D',
      value: 'd',
    },
    {
      label: 'E',
      value: 'e',
    },
    {
      label: 'F',
      value: 'f',
    },
    {
      label: 'G',
      value: 'g',
    },
    {
      label: 'H',
      value: 'h',
    },
    {
      label: 'I',
      value: 'i',
    },
    {
      label: 'J',
      value: 'j',
    },
    {
      label: 'K',
      value: 'k',
    },
    {
      label: 'L',
      value: 'l',
    },
    {
      label: 'M',
      value: 'm',
    },
    // {
    //   label: 'M+',
    //   value: 'm+',
    // },
  ],
}
