/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {PageTitle} from '../../../../_metronic/layout/core'
import {createBannerData, getBannerData} from '../redux/BannerCRUD'
import * as banner from '../redux/BannerRedux'
import {CreateBannerForm} from './CreateBannerForm'
import {IBannerFormValues} from './CreateBannerForm'
import {Toaster} from '../../../modules/comman/components/Toaster'

const ListPage: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  // call initial data for Form
  const callInitialData = async () => {
    try {
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  useEffect(() => {
    callInitialData()
  }, [])

  const callBannerData = async () => {
    try {
      let shipmentPriceData = await getBannerData(0, 10)
      if (shipmentPriceData.data) {
        await dispatch(banner.actions.setBannerLoading(true))
        // once banner data set then loading will automatically off using saga middleware
        await dispatch(banner.actions.setBannerData(shipmentPriceData.data.docs))
        await dispatch(banner.actions.setBannerDataTotal(shipmentPriceData.data.total))
        history.push('/banner/list')
      }
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmit = async (values: IBannerFormValues) => {
    try {
      await createBannerData(values)
      Toaster({
        type: 'success',
        title: 'BANNER',
        description: 'Banner Created Successfully',
      })
      await callBannerData()
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <CreateBannerForm onSubmit={(values: IBannerFormValues) => addSubmit(values)} />
    </div>
  )
}

const CreateWrapper: FC = () => {
  const intl = useIntl()

  return (
    <div>
      <PageTitle
        breadcrumbs={[
          {
            title: 'Create',
            path: '/banner/list',
            isActive: true,
            isSeparator: false,
          },
          {
            title: 'Create',
            path: '/banner/list',
            isActive: true,
            isSeparator: true,
          },
        ]}
      >
        {intl.formatMessage({id: 'MENU.BANNER'})}
      </PageTitle>
      <ListPage />
    </div>
  )
}

export {CreateWrapper}
