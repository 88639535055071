import React from 'react'
import axios from 'axios'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_BANNER_URL = `${API_URL}v1/auth/banner/`
export const SEARCH_BANNER_URL = `${API_URL}v1/auth/filter-banner/`
export const CREATE_BANNER_URL = `${API_URL}v1/auth/banner/`
export const EDIT_BANNER_URL = `${API_URL}v1/auth/banner/`
export const DELETE_BANNER_URL = `${API_URL}v1/auth/banner/`
export const CHANGE_STATUS_BANNER_URL = `${API_URL}v1/auth/banner/active-status/`

// Get Order Data
export async function getBannerData(startIndex: number, limitNumber: number) {
  try {
    let bannerData = await axios.get(`${GET_BANNER_URL}${startIndex}/${limitNumber}`)
    return bannerData
  } catch (e) {
    throw e
  }
}

// Search Order Data
export async function getSearchBannerData(
  startIndex: number,
  limitNumber: number,
  searchText: string
) {
  try {
    let bannerData = await axios.post(`${SEARCH_BANNER_URL}${startIndex}/${limitNumber}`, {
      searchText,
    })
    return bannerData
  } catch (e) {
    throw e
  }
}

// Create Order Data
export async function createBannerData(orderObject: any) {
  try {
    let createBanner = await axios.post(`${CREATE_BANNER_URL}`, {
      ...orderObject,
    })
    return createBanner
  } catch (e) {
    throw e
  }
}

// Delete Order Data
export async function deleteBannerData(bannerId: string) {
  try {
    let deleteOrderData = await axios.delete(`${DELETE_BANNER_URL}${bannerId}`)
    return deleteOrderData
  } catch (e) {
    throw e
  }
}

// Change Status Order Data
export async function changeStatusBannerData(bannerId: string, flag: boolean) {
  try {
    let bannerData = await axios.put(`${CHANGE_STATUS_BANNER_URL}`, {
      bannerId,
      flag,
    })
    return bannerData
  } catch (e) {
    throw e
  }
}

// Edit Order Data
export async function editBannerData(orderObject: any, bannerId: string) {
  try {
    let editBannerData = await axios.put(`${EDIT_BANNER_URL}`, {
      ...orderObject,
      bannerId,
    })
    return editBannerData
  } catch (e) {
    throw e
  }
}
