import * as React from 'react'
import {Formik, FieldProps, Field} from 'formik'
import * as moment from 'moment'
import {InputText} from '../../../modules/comman/formComponents/InputText'
import {InputButtonComponent} from '../../../modules/comman/formComponents/InputButtonComponent'
import {getRoleDataForDropDown} from '../../role/redux/RoleCRUD'
import {InputSingleSelect} from '../../../modules/comman/formComponents/InputSingleSelect'
import {Popconfirm} from 'antd'

interface IRenderFormikForm {
  values: IClientFormValues
  errors: any
  touched: any
  handleChange: any
  handleBlur: any
  handleSubmit: any
  isSubmitting: any
  handleDelete: any
  setFieldValue: any
  setFieldTouched: any
}

export interface IClientFormValues {
  address_line_1: string
  address_line_2: string
  city: string
  state: string
  country: string
  postal_code: string
  shipment_address_line_1: string
  shipment_address_line_2: string
  shipment_city: string
  shipment_state: string
  shipment_country: string
  shipment_postal_code: string
}

interface IEditClientProps {
  onSubmit: any
  loginUser: any
  handleCancel?: any
  deleteCart: any
}

export class ClientCartDataForm extends React.Component<IEditClientProps, any> {
  constructor(props: any) {
    super(props)

    this.state = {
      data: [],
      isLoading: false,
      roleData: [],
    }
  }

  public validateForm = (values: IClientFormValues) => {
    const errors: any = {}

    if (!values.address_line_1) {
      errors.address_line_1 = 'Address is required'
    }

    if (!values.country) {
      errors.country = 'Country is required'
    }

    if (!values.state) {
      errors.state = 'State is required'
    }

    if (!values.city) {
      errors.city = 'City is required'
    }

    if (!values.postal_code) {
      errors.postal_code = 'Postal Code is required'
    }

    if (!values.shipment_address_line_1) {
      errors.shipment_address_line_1 = 'Address is required'
    }

    if (!values.shipment_country) {
      errors.shipment_country = 'Country is required'
    }

    if (!values.shipment_state) {
      errors.shipment_state = 'State is required'
    }

    if (!values.shipment_city) {
      errors.shipment_city = 'City is required'
    }

    if (!values.shipment_postal_code) {
      errors.shipment_postal_code = 'Postal Code is required'
    }

    return errors
  }

  public handleSubmit = (values: IClientFormValues, action: any) => {
    const {handleCancel, onSubmit} = this.props

    onSubmit(values)
    handleCancel()

    this.setState({
      visible: false,
    })
  }

  public showModal = () => {
    this.setState({
      visible: true,
    })
  }

  public handleOk = () => {
    this.setState({
      visible: false,
    })
  }

  public handleCancel = () => {
    this.setState({
      visible: false,
    })
  }

  public changeBankName = (value: string) => {
    this.setState({
      bankSelectName: value,
    })
  }

  public render() {
    const {loginUser} = this.props

    return (
      <div>
        <div className='card card-custom'>
          <div className='card-body'>
            <div>
              <Formik
                initialValues={{
                  address_line_1: loginUser.billing_address?.address_line_1
                    ? loginUser.billing_address?.address_line_1
                    : '',
                  address_line_2: loginUser.billing_address?.address_line_2
                    ? loginUser.billing_address?.address_line_2
                    : '',
                  city: loginUser.billing_address?.city ? loginUser.billing_address?.city : '',
                  state: loginUser.billing_address?.state ? loginUser.billing_address?.state : '',
                  country: loginUser.billing_address?.country
                    ? loginUser.billing_address?.country
                    : '',
                  postal_code: loginUser.billing_address?.postal_code
                    ? loginUser.billing_address?.postal_code
                    : '',
                  shipment_address_line_1: loginUser.shipping_address?.address_line_1
                    ? loginUser.shipping_address?.address_line_1
                    : '',
                  shipment_address_line_2: loginUser.shipping_address?.address_line_2
                    ? loginUser.shipping_address?.address_line_2
                    : '',
                  shipment_city: loginUser.shipping_address?.city
                    ? loginUser.shipping_address?.city
                    : '',
                  shipment_state: loginUser.shipping_address?.state
                    ? loginUser.shipping_address?.state
                    : '',
                  shipment_country: loginUser.shipping_address?.country
                    ? loginUser.shipping_address?.country
                    : '',
                  shipment_postal_code: loginUser.shipping_address?.postal_code
                    ? loginUser.shipping_address?.postal_code
                    : '',
                }}
                validate={this.validateForm}
                onSubmit={this.handleSubmit}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }: IRenderFormikForm) => {
                  return (
                    <div>
                      <div>
                        <div style={{fontSize: '20px', fontWeight: 600, marginBottom: '30px'}}>
                          <h3>Billing Address</h3>
                        </div>
                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.address_line_1,
                                id: 'address_line_1',
                                name: 'address_line_1',
                              }}
                              placeholder='Enter address_line_1'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('address_line_1', value)
                              }}
                              onBlur={handleBlur}
                              label='Address Line 1'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.address_line_2,
                                id: 'address_line_2',
                                name: 'address_line_2',
                              }}
                              placeholder='Enter address_line_2'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('address_line_2', value)
                              }}
                              onBlur={handleBlur}
                              label='Address Line 2'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.city,
                                id: 'city',
                                name: 'city',
                              }}
                              placeholder='Enter city'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('city', value)
                              }}
                              onBlur={handleBlur}
                              label='City'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.state,
                                id: 'state',
                                name: 'state',
                              }}
                              placeholder='Enter state'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('state', value)
                              }}
                              onBlur={handleBlur}
                              label='State'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.country,
                                id: 'country',
                                name: 'country',
                              }}
                              placeholder='Enter country'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('country', value)
                              }}
                              onBlur={handleBlur}
                              label='Country'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.postal_code,
                                id: 'postal_code',
                                name: 'postal_code',
                              }}
                              placeholder='Enter postal code'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('postal_code', value)
                              }}
                              onBlur={handleBlur}
                              label='Postal Code'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div>
                        <div style={{fontSize: '20px', fontWeight: 600, marginBottom: '30px'}}>
                          <h3>Shipment Address</h3>
                        </div>
                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.shipment_address_line_1,
                                id: 'shipment_address_line_1',
                                name: 'shipment_address_line_1',
                              }}
                              placeholder='Enter shipment_address_line_1'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('shipment_address_line_1', value)
                              }}
                              onBlur={handleBlur}
                              label='Address Line 1'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.shipment_address_line_2,
                                id: 'shipment_address_line_2',
                                name: 'shipment_address_line_2',
                              }}
                              placeholder='Enter shipment_address_line_2'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('shipment_address_line_2', value)
                              }}
                              onBlur={handleBlur}
                              label='Address Line 2'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.shipment_city,
                                id: 'shipment_city',
                                name: 'shipment_city',
                              }}
                              placeholder='Enter city'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('shipment_city', value)
                              }}
                              onBlur={handleBlur}
                              label='City'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.shipment_state,
                                id: 'shipment_state',
                                name: 'shipment_state',
                              }}
                              placeholder='Enter state'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('shipment_state', value)
                              }}
                              onBlur={handleBlur}
                              label='State'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>

                        <div className='form-group row'>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.shipment_country,
                                id: 'shipment_country',
                                name: 'shipment_country',
                              }}
                              placeholder='Enter country'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('shipment_country', value)
                              }}
                              onBlur={handleBlur}
                              label='Country'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                          <div className='col-md-6 col-lg-6 col-sm-12'>
                            <InputText
                              input={{
                                value: values.shipment_postal_code,
                                id: 'shipment_postal_code',
                                name: 'shipment_postal_code',
                              }}
                              placeholder='Enter postal code'
                              onChange={(value: string) => {
                                handleChange(value)
                                setFieldValue('shipment_postal_code', value)
                              }}
                              onBlur={handleBlur}
                              label='Postal Code'
                              error={errors}
                              touched={touched}
                              required={false}
                            />
                          </div>
                        </div>
                      </div>

                      <div className='custom-card-footer'>
                        {/* <div className='float-right'>
                          <InputButtonComponent onClick={handleSubmit}>Save</InputButtonComponent>
                        </div> */}
                        <div className='d-flex justify-content-end mt-2'>
                          <div className='mr-5'>
                            <InputButtonComponent onClick={handleSubmit}>
                              {'Create Buy Request'}
                            </InputButtonComponent>
                          </div>
                          <div className=''>
                            <Popconfirm
                              onConfirm={() => {
                                //call delete client api
                                this.props.deleteCart()
                              }}
                              title='Are you sure you want to delete cart?'
                            >
                              <button type='button' className='btn btn-danger btn-sm'>
                                {'Clear Cart'}
                              </button>
                            </Popconfirm>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
