import axios from 'axios'
import {UserModel} from '../models/UserModel'

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:2806/'

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}v1/auth/get-admin/`
export const LOGIN_URL = `${API_URL}v1/admin-login/`
export const REGISTER_URL = `${API_URL}v1/register`
export const REQUEST_FORGOT_PASSWORD_URL = `${API_URL}v1/forgot-password/`
export const REQUEST_RESET_PASSWORD_URL = `${API_URL}v1/reset-password/`
export const REQUEST_CHANGE_PASSWORD_URL = `${API_URL}v1/auth/change-admin-password/`
export const REQUEST_EDIT_ADMIN = `${API_URL}v1/auth/edit-admin/`
export const REQUEST_EDIT_ADMIN_BANK_DETAILS = `${API_URL}v1/auth/edit-account/`
export const VERIFY_EMAIL = `${API_URL}v1/client/verify/`

// Server should return AuthModel
export async function login(email: string, password: string) {
  try {
    let loginData = await axios.post(LOGIN_URL, {email, password})
    return loginData.data
  } catch (e) {
    throw e
  }
}

// Server should return AuthModel
export function register(
  role_id: string,
  first_name: string,
  last_name: string,
  email: string,
  username: string,
  password: string,
  company_name: string,
  phone: string,
  phone_iso: string
) {
  return axios.post(REGISTER_URL, {
    role_id,
    first_name,
    last_name,
    email,
    username,
    password,
    company_name,
    phone,
    phone_iso,
  })
}

// Server should mail thr reset link
export async function requestPassword(email: string) {
  try {
    let forgotData = await axios.post(REQUEST_FORGOT_PASSWORD_URL, {email})
    return forgotData.data
  } catch (e) {
    throw e
  }
}

// Server will reset password
export async function resetPassword(forgotCode: string, password: string) {
  try {
    let resetPasswordData = await axios.post(REQUEST_RESET_PASSWORD_URL, {forgotCode, password})
    return resetPasswordData.data
  } catch (e) {
    throw e
  }
}

// Server will change password
export async function changePassword(password: string, confirm_password: string) {
  try {
    let changePasswordData = await axios.post(REQUEST_CHANGE_PASSWORD_URL, {
      password,
      confirm_password,
    })
    return changePasswordData.data
  } catch (e) {
    throw e
  }
}

// Server will edit admin
export async function editAdmin(adminObject: {email: string; email_notification: boolean}) {
  try {
    let editAdminData = await axios.post(REQUEST_EDIT_ADMIN, {...adminObject})
    return editAdminData.data
  } catch (e) {
    throw e
  }
}

// Server will edit admin
export async function editAdminBankAccountDetails(adminObject: {
  account_number: string
  account_name: string
  account_bank: string
  account_ifsc: string
  routing_number: string
  name: string
  phone: string
  skype: string
  whatsapp: string
}) {
  try {
    let updateAccountData = await axios.post(REQUEST_EDIT_ADMIN_BANK_DETAILS, {...adminObject})
    return updateAccountData.data
  } catch (e) {
    throw e
  }
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  // Check common redux folder => setupAxios
  return axios.get<UserModel>(GET_USER_BY_ACCESSTOKEN_URL)
}

// verify email id from link which client get on their email
export async function verifyEmail(clientId: string) {
  try {
    let emailVerifyData = await axios.post(VERIFY_EMAIL, {clientId})
    return emailVerifyData
  } catch (e) {
    throw e
  }
}

// Server will edit admin
export async function editSystemSettings(adminObject: {system_mail_email: string}) {
  try {
    let editSystemSettingsData = await axios.post(REQUEST_EDIT_ADMIN, {...adminObject})
    return editSystemSettingsData.data
  } catch (e) {
    throw e
  }
}
