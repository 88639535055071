/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import ListCartData from './ListCartData'
import {
  createBuyRequestData,
  createBuyRequestDataWithValues,
} from '../../buyRequest/redux/BuyRequestCRUD'
import * as inventory from '../../inventory/redux/InventoryRedux'
import * as auth from '../../../modules/auth/redux/AuthRedux'
import {IBuyRequestFormValues} from '../../buyRequest/containers/CreateBuyRequestForm'
import {UserModel} from '../../../modules/auth/models/UserModel'

const DisplayCart: FC = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const addSubmit = async (values: IBuyRequestFormValues) => {
    try {
      await createBuyRequestData(values)
      await dispatch(inventory.actions.setInventoryCartData(undefined))
      history.push('/buy-request/list')
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  const addSubmitWithFormValues = async (values: any, formValues: any) => {
    try {
      let loginUserData: UserModel = await createBuyRequestDataWithValues(values, formValues)
      console.log('!!!!loginUserData', loginUserData)
      await dispatch(auth.actions.setUser(loginUserData))
      await dispatch(inventory.actions.setInventoryCartData(undefined))
      history.push('/buy-request/list')
    } catch (e) {
      console.log('!!!!Error', e)
    }
  }

  return (
    <div>
      <div style={{marginBottom: '30px'}}>
        <ListCartData
          onSubmit={(values: IBuyRequestFormValues) => addSubmit(values)}
          onSubmitWithFormValues={(values: any, formValues: any) =>
            addSubmitWithFormValues(values, formValues)
          }
        />
      </div>
    </div>
  )
}

export {DisplayCart}
